import { blockContent } from './blockContent'

export const customContactForm = `
_type == 'customContactForm' => {
  ...,
  formFields[] {
    ...,
    defined(fieldId) => {
      'fieldId': fieldId.current
    }
  },
  content[] {
    ${blockContent}
  }
}`
