<template>
  <div class="page-wrapper">
    <component
      :is="component.component"
      v-for="(component, index) in components"
      :key="index"
      v-bind="component.data"
      @mounted="updateNavStyling"
      @updated="updateNavStyling"
    />
  </div>
</template>

<script setup lang="ts">
import { createClient } from '@sanity/client'
import {
  hero,
  cards,
  productsNavigation,
  stats,
  infoSection,
  soloCta,
  accordions,
  content,
  splitContent,
  imageBanner,
  multiInfoSection,
  splitImages,
  inlineImages,
  customContactForm,
  testimonials,
  customSection,
  imageGallery
} from '../../component_querys'

interface Props {
  slug: string;
  documentType?: string;
}
const props = withDefaults(
  defineProps<Props>(),
  {
    documentType: 'page'
  }
)

interface Page {
  title: string;
  description: string;
  seo?: SEOData;
  pageBuilder: any[];
  newsPageBuilder: any[];
}
const route = useRoute()
const mainQuery = `
    ...,
    ${stats},
    ${hero},
    ${imageGallery},
    ${cards},
    ${productsNavigation},
    ${infoSection},
    ${multiInfoSection},
    ${soloCta},
    ${accordions},
    ${content},
    ${splitContent},
    ${splitImages},
    ${imageBanner},
    ${inlineImages},
    ${customContactForm},
    ${testimonials},
    ${customSection}
`
const query = groq`*[_type == "${props.documentType}" && slug.current == "${props.slug}" ][0] {
  ...,
  'seo': seo {
    ogTitle,
    ogDescription,
    'ogImage': ogImage.asset->url + '?auto=format&w=1200&h=630',
    twitterCard,
  },
  'pageBuilder': pageBuilder[]{
    defined(_ref) => {
      ...@->content[0] {
        ${mainQuery}
      }
    },
    !defined(_ref) => {
      ${mainQuery},
    }
  },
  'newsPageBuilder': newsPageBuilder[]{
    defined(_ref) => {
      ...@->content[0] {
        ${mainQuery}
      }
    },
    !defined(_ref) => {
      ${mainQuery},
    }
  }
}`

const components = shallowRef()
const isPreview = route.query.preview === 'true'
if (isPreview) { useSanity().config.perspective = 'previewDrafts' } else { useSanity().config.perspective = 'published' }
const sanity = createClient(useSanity().config)

// get initial page data
const { data, error } = await useAsyncData(async () => {
  return await sanity.fetch<Page>(query)
})

if (!data.value || error.value) { throw createError({ statusCode: 404, fatal: true }) }
// update page with data
await updatePage(data.value)

// methods
async function updatePage (data: Page) {
  useHead({
    title: data.title,
    meta: [
      { name: 'description', content: data.description }
    ]
  })
  setPageSEO(data.seo)

  const resolvedData = await resolveDeeplyNestedSanityRefs(data, sanity, mainQuery)
  components.value = updateComponents(resolvedData.pageBuilder ?? resolvedData.newsPageBuilder ?? [])
}

function updateComponents (pageBuilder: any[]) {
  // import components
  return pageBuilder.map((component) => {
    const { _type, ...data } = component
    // const baseDir = import.meta.url.toString().split('/base/pageBuilder')[0]
    return {
      data: { ...data },
      component: defineAsyncComponent(() => import(`../pageBuilder/${_type}.vue`))
    }
  })
}
</script>
