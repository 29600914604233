import { blockContent } from './blockContent'

export const lastSection = `
...,
defined(content) => {
  content[]{
    ${blockContent}
  }
}
`

export const section = `
...,
defined(sections) => {
  sections[]{
    ...,
    defined(sections) => {
      sections[]{
        ...,
        defined(sections) => {
          ${lastSection}
        },
        defined(content) => {
          content[]{
            ${blockContent}
          }
        }
      }
    },
    defined(content) => {
      content[]{
        ${blockContent}
      }
    }
  }
},
defined(content) => {
  content[]{
    ${blockContent}
  }
},
`

export const customSection = `
_type == 'customSection' => {
  ...,
  defined(sections) => {
    sections[]{
      ${section}
    }
  },
  defined(content) => {
    content[]{
      ${blockContent}
    }
  },
}`
