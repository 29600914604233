export const imageGallery = `
_type == 'imageGallery' => {
  'display': display,
  'zoom': zoom,
  images[] {
    'url': asset->url + '?auto=format&max-w=2560',
    'alt': alt
  }
}
`
