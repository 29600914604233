import { blockContent } from './blockContent'

export const hero = `
_type == 'hero' => {
  ...,
  defined(heroImage) => {
    'heroImage': heroImage {
      'asset': asset->url + '?auto=format',
      alt
    }
  },
  defined(bgImage) => {
    'bgImage': bgImage.asset->url + '?auto=format&w=1920',
  },
  defined(bgVideo) => {
    'bgVideo': bgVideo.asset->url,
  },
  defined(threeDModel) => {
    'threeDModel': {
      'zipFile': threeDModel.zipFile.asset->url,
      'posision': threeDModel.posision,
      'colors': threeDModel.colors,
      'controls': threeDModel.controls,
      'rotate': threeDModel.rotate
    }
  },
  mainContent[] {
    ${blockContent}
  },
  ctas[] {
    ...,
    defined(internal) => {
      'internal': select(
        internal->slug.current == '/' => internal->slug.current,
        internal->slug.current != '/' => '/'+internal->slug.current,
      ),
    },
    defined(file) => {
      'file': file.asset->url
    }
  }
}`
